.terms-modal {
  .doc-title {
    display: flex;
    padding: 15px;
    // border-radius: 15px 15px 0 0;
    background: #c81e1e;
  }
  .ps-container {
    height: 600px !important;
  }
  .doc-title h2 {
    margin: 0;
    color: white;
  }

  .scroll-area {
    background: white;
  }
  .vs-dialog {
    border-radius: 25px;
  }

  .vs-dialog__content {
    border-radius: 25px;
  }

  .wide-popup .vs-dialog {
    height: 100%;
  }
  .vs-dialog__content.notFooter {
    height: 100%;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  .doc-accept {
    display: flex;
    padding: 10px 15px;
    background: #c81e1e;
    border-radius: 0 0 15px 15px;
  }

  .doc-accept .button:hover {
    background: none;
  }
}

ol {
  margin: 0 0 0.2rem 0.7rem;
  padding: 0;
}

#printText {
  ol li {
    margin: 0;
    padding: 0;
    list-style: decimal !important;
  }
}

#rich-text-body {
  .wpb_wrapper {
    h1,
    h2,
    h3 {
      margin: 20px 0 10px 0;
    }
  }
}
