
































































































































































































































.bg-img {
  background-image: url('../../../assets/images/pages/vuexy-login-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.register {
  .Panel-StyledPanel {
    border: unset !important;
    box-shadow: unset !important;
  }
}
.full-page-bg-color {
  background-color: #eff2f7;
}
